import React from "react"
import Layout from "../components/Layout"
import BlogPosts from "../components/blog/BlogPosts"

const blog = props => {
  return (
    <Layout
      title="DataRight blog"
      description="DataRight blog"
      contentType="article"
    >
      <div className="default-center">
        <BlogPosts />
      </div>
    </Layout>
  )
}

export default blog
