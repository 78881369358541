import React from "react"
import Image from "gatsby-image"
import Link from "../Link"
import Tags from "../Tags"
import styles from "../../css/PostCard.module.css"

const PostPresentation = ({ data, position }) => {
  const featImgFluid = data.featuredImg.childImageSharp.fluid
  const { slug, title, description, tags, date } = data.frontmatter
  const url = `/blog/${slug}`

  return (
    <section className={styles.postRep}>
      <div className={styles.featImg}>
        <Link to={url}>
          <Image fluid={featImgFluid} />
        </Link>
      </div>
      <div className={styles.postDesc}>
        <div className={styles.date}>{date}</div>
        <Link to={url}>
          <article>
            <h1>{title}</h1>
            <p>{description}</p>
          </article>
        </Link>
        <div className={styles.tags}>
          <Tags tags={tags} />
        </div>
      </div>
    </section>
  )
}

export default PostPresentation
