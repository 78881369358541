import React from "react"
import styles from "../../css/BlogPosts.module.css"
import { useStaticQuery, graphql } from "gatsby"

import PostCard from "./PostCard"

const getBlogPosts = graphql`
  {
    posts: allMdx(
      filter: { fileAbsolutePath: { regex: "/blog//" } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            featuredImgUrl
            date(formatString: "MMM Do, YYYY")
            time: date(formatString: "HH:mm:ss")
            author
            tags
            slug
            title
            description
          }
          excerpt
          featuredImg {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
    }
  }
`

const BlogPosts = () => {
  const posts = useStaticQuery(getBlogPosts).posts.edges

  return (
    <div className={styles.postList}>
      {posts.map(({ node }, index) => {
        return (
          <div
            className={
              index === 0
                ? styles.postRep
                : `${styles.postRep} ${styles.postSep}`
            }
          >
            <PostCard key={index} data={node} position={index} />
          </div>
        )
      })}
    </div>
  )
}

export default BlogPosts
